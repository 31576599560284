<template>
  <div>
    <v-carousel class="">
      <v-carousel-item
        :src="item.slider_image"
        v-for="item in slider"
        :key="item.id"
        :aspect-ratio="16/9"
        style="height:auto; width:100%"
        class="mx-auto"
      >
      </v-carousel-item>
    </v-carousel>
</div>
</template>
<script>
export default {
  name: "slider",
  props: {
    slider:{
      type:Array,
      default:null,
    }
  },
  data() {
    return {
      default_slider:require(`@/assets/default_slider.png`),
    };
  },
  mounted() {
  if(this.slider.length===0){
    this.slider=[{
      slider_text:'',
      slider_image:this.default_slider,
    }];
  } 
},
  methods: {},
};
</script>
