<script>
import slider from "@/components/widgets/slider/slider.vue";
import Policy from "./Policy.vue";
import DiscountSales from "@/components/DiscountSales.vue";
import Categories from "./Categories.vue";
import HotDeals from "./HotDeals.vue";
import axios from "axios";
import Swal from "sweetalert2";

export default {
  components: {
    Categories,
    DiscountSales,
    HotDeals,
    Policy,
    slider,
  },
  data() {
    return {
      inventorydata: [],
      discountSales: [],
      hotDeals: [],
      newArrivals: [],
      slider: [],
      currentPage: 1,
      perPage: 8,
      limit: 8,
      offset: 0,
      totalProducts: 0,
      default_slider:require(`@/assets/default_slider.png`),
    };
  },
  async mounted() {
    await this.updateArrays();
  },
  methods: {
    async updateArrays() {
      Swal.fire({
        title: "Please Wait !",
        html: "Loading data...", // add html attribute if you want or remove
        allowOutsideClick: false,
        showConfirmButton: false,
        willOpen: () => {
          Swal.showLoading();
        },
      });
      await axios
        .get(window.$http + `stock/?limit=${this.limit}&offset=${this.offset}`,{headers:window.$headers})
        .then((response) => {
          this.inventorydata = response.data["results"];
          this.totalProducts = response.data["count"];
          //this.dealsData = this.inventorydata.filter((f) => f.is_deal_of_the_day == true);
          this.discountSales = this.inventorydata.filter((item) => item.discount !== null);
          console.log(this.discountSales)
          this.hotDeals = this.inventorydata
            .filter((f) => f.is_top_pick == true)
            .slice(0, 12);
          this.newArrivals = this.inventorydata
            .filter((f) => f.is_new_arrival == true)
            .slice(0, 12);
          axios.get(window.$http + `store`,{headers:window.$headers}).then((res) => {
            this.slider = res.data["results"];
            if(this.slider.length===0){
              this.slider=[{
                slider_text:'',
                slider_image:this.default_slider,
              }];
            } 
          }).then(()=>{
            const shouldReload = sessionStorage.getItem('reloadOnce');
            if (shouldReload !== 'true') {
              // Perform the reload
              window.location.reload();
              // Set the flag to indicate that the window has been reloaded once
              sessionStorage.setItem('reloadOnce', 'true');
            }
          });
          Swal.close();
        })
        .catch((e) => {
          Swal.fire({
            position: "center",
            icon: "error",
            title: "" + e,
            showConfirmButton: true,
          }).then((e) => {
            Swal.close(e);
          });
        });
    },
    handlePageChange(newPage) {
      this.currentPage = newPage;
      this.limit = this.perPage;
      this.offset = (this.currentPage - 1) * this.perPage;
      this.updateArrays();
    },
  },
};
</script>
<style scoped>
  .scrolling-container {
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}

.col-md-3 {
  width: calc(100% / 4); /* Adjust the width to fit 4 items in a row */
  min-width: 200px; /* Set a minimum width for each item */
}
.scrollable .v-card-text {
  max-height: 200px;
  /* Set a maximum height to enable scrolling */
  overflow-y: auto;
  /* Enable vertical scrolling */
}
.scrolling-container {
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}

.col-lg-3,
.col-md-4,
.col-sm-6 {
  flex: 0 0 auto;
  padding-right: 15px; /* Adjust spacing between items */
  padding-left: 15px; /* Adjust spacing between items */
}
.col-lg-3 {
  max-width: 25%; /* Display up to 4 items in a row on large screens */
}
.col-md-4 {
  max-width: 33.333%; /* Display up to 3 items in a row on medium screens */
}
.col-sm-6 {
  max-width: 50%; /* Display up to 2 items in a row on small screens */
}
.col-12 {
  max-width: 100%; /* Display 1 item in a row on extra small screens */
}
</style>

<template>
  <div class="container-fluid">
    <div class="row ml-2 mr-2 pl-2 pr-2">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <slider :slider="slider" />
      </div>
    </div>
    <div class="row">
      <Categories />
      <HotDeals :hotDeals="hotDeals" v-if="hotDeals.length>0" />
        <v-img v-else :src="require(`@/assets/hot_deals.jpg`)" class="img-thumbnail" :aspect-ratio="16/9" style="max-height: 450px;width: 100%;"></v-img>
    </div>
    <div class="row">
      <Policy />
      <DiscountSales
        :discountSales="discountSales"
        :newArrivals="newArrivals"
        v-show="(discountSales.length>0||newArrivals.length>0)"
      />
      <div class="container">
            <h4 class="bg-secondary text-white rounded-3 p-2 text-center">Shop by Brand</h4>
            <div class="row">
            <div class="col-xl-4 col-lg-6 col-md-8 col-sm-12" v-for="item in inventorydata.filter(x=>x.product.brand !==null)" :key="item">
              <v-card v-if="item.product.brand">
                <div class="card-title">
                  <span class="badge badge-pill bg-warning">{{ item.product.brand.title }}</span>
                </div>
                <v-img :src="item.product.images[0].image" :aspect-ratio="5 / 4"></v-img>
                <div class="card-title m-2 text-center">{{`${item.variation?item.variation.title:''+' '+item.product.title}` }}</div>
                  <div class="row m-1 text-center">
                    <router-link :to="{name: 'Product Detail',params: { stockitem: item },}" class="btn btn-warning text-white">SHOP NOW</router-link>
                  </div>
                </v-card>
            </div>
        </div>
      </div>
      <div class="container">
            <h4 class="bg-secondary text-white  rounded-3 p-2 text-center">Shop by Category</h4>
            <div class="row">
            <div class="col-xl-4 col-lg-6 col-md-8 col-sm-12" v-for="item in inventorydata.filter(x=>x.product.maincategory !==null)" :key="item">
              <v-card v-if="item.product.maincategory">
                <div class="card-title">
                  <span class="badge badge-pill bg-warning">{{ item.product.maincategory.name }}</span>
                </div>
                <v-img :src="item.product.images[0].image" :aspect-ratio="5 / 4"></v-img>
                <div class="card-title m-2 text-center">{{`${item.variation?item.variation.title:''+' '+item.product.title}` }}</div>
                  <div class="row m-1 text-center">
                    <router-link :to="{name: 'Product Detail',params: { stockitem: item },}" class="btn btn-warning text-white">SHOP NOW</router-link>
                  </div>
                </v-card>
            </div>
        </div>
      </div>
  </div>
  <div class="row mb-2">
    <v-pagination
      v-if="totalProducts > 1"
      v-model="currentPage"
      :total-visible="3"
      :prev-text="'Previous'"
      :next-text="'Next'"
      :length="Math.ceil(totalProducts / perPage)"
      @input="handlePageChange(currentPage)"
    ></v-pagination>
  </div>
  </div>
</template>
