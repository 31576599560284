<template>
  <div class="container">
    <h4 class="text-center bg-warning text-light rounded-3 mt-2">New Arrivals</h4>
    <div class="row overflow-auto">
      <div class="col-lg-12">
      <div class="scrolling-container">
        <div class="d-flex flex-nowrap">
          <div
            class="col-lg-3 col-md-4 col-sm-6 col-12"
            v-for="item in newArrivals"
            :key="item"
          >
            <v-card v-if="item">
              <v-img
                :aspect-ratio="16 / 9"
                :src="item.product.images[0].image"
                contain
                class="white--text align-center"
                gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                height="350px"
              >
                <div class="text-center">
                  <v-btn
                    :to="{
                      name: 'Product Detail',
                      params: { stockitem: item },
                    }"
                    class="white--text"
                    outlined
                    >SHOP NOW</v-btn
                  >
                </div>
              </v-img>
            </v-card>
          </div>
        </div>
      </div>
      </div>
  </div>

    <div class="row" v-if="discountSales.length>0">
      <div
        class="col-lg-12 col-sm-12 col-md-6 col-md-3 col-sm-3 flex flex-row flex-column"
      >
        <h3 class="bg-warning p-2 rounded-2">
          Flash Sale
          <p class="badge badge-pill bg-danger d-inline">days:</p>
          <b> {{ days }} </b>
          <p class="badge badge-pill bg-danger d-inline">hours:</p>
          <b> {{ hours }} </b>
          <p class="badge badge-pill bg-danger d-inline">minutes:</p>
          <b> {{ minutes }} </b>
          <p class="badge badge-pill bg-danger d-inline">seconds:</p>
          <b> {{ seconds }} </b>
        </h3>
      </div>
      <div class="col-md-4 col-sm-4 col-xs-12" v-for="item in discountSales" :key="item.id">
        <v-card outlined>
          <v-img
            :aspect-ratio="16 / 9"
            :src="item.product.images[0].image"
            class="white--text align-center img-fluid"
            gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
          >
            <h2 class="text-center font-size text-white bg-warning">
              {{ getFirst3Words(item.product.title)
              }}<span v-if="item.size" class="badge badge-pill"
                >{{ `..${item.variation!==null?item.variation.title:''}` }}</span
              >
            </h2>
            <div class="text-center mt-2">
              <v-btn
                class="white--text caption bg-danger"
                :to="{
                  name: 'Product Detail',
                  params: { stockitem: item },
                }"
                text
                >SHOP NOW
                <v-icon class="white--text caption">mdi-arrow-right</v-icon></v-btn
              >
            </div>
          </v-img>
        </v-card>
      </div>
    </div>
  </div>
</template>
<script>
// import axios from "@/Axiosconfig";
// import Swal from "sweetalert2";

export default {
  props: {
    discountSales: Array,
      newArrivals: Array,
      flash_sale_date: String,
  },
  data() {
    return {
      endTime: new Date("2023-12-10T00:00:00.000Z"),
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      timerInterval: null,
    };
  },
  mounted() {
    //setInterval(() => {
    //   const now = new Date().getTime();
    //   const distance = this.endTime - now;
    //   if (this.endTime > now) {
    //     this.days = Math.floor(distance / (1000 * 60 * 60 * 24));
    //     this.hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    //     this.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    //     this.seconds = Math.floor((distance % (1000 * 60)) / 1000);
    //   }
    // }, 1000);
  },
  methods: {
    // updateArrays() {
    //   Swal.fire({
    //     title: "Please Wait !",
    //     html: "Loading data...", // add html attribute if you want or remove
    //     allowOutsideClick: false,
    //     showConfirmButton: false,
    //     willOpen: () => {
    //       Swal.showLoading();
    //     },
    //   });
    //   axios
    //     .get(window.$http + `stock/?limit=${this.limit}&offset=${this.offset}`,{headers:window.$headers})
    //     .then((response) => {
    //       this.inventorydata = response.data["results"];
    //       this.totalProducts = response.data["count"];
    //       //this.dealsData = this.inventorydata.filter((f) => f.is_deal_of_the_day == true);
    //       this.discountSales = this.inventorydata.filter((item) => item.discount !== null);
    //       console.log(this.discountSales)
    //       this.topPicks = this.inventorydata
    //         .filter((f) => f.is_top_pick == true)
    //         .slice(0, 3);
    //       this.newArrivals = this.inventorydata
    //         .filter((f) => f.is_new_arrival == true)
    //         .slice(0, 3);
    //       axios.get(window.$http + `store`,{headers:window.$headers}).then((res) => {
    //         this.slider = res.data["results"];
    //       });
    //       Swal.close();
    //     })
    //     .catch((e) => {
    //       Swal.fire({
    //         position: "center",
    //         icon: "error",
    //         title: "" + e,
    //         showConfirmButton: true,
    //       }).then((e) => {
    //         Swal.close(e);
    //       });
    //     });
    // },
    getFirst3Words(str) {
      // Split the sentence into an array of words
      const words = str.split(" ");
      // Take the first two words
      const firstTwoWords = words.slice(0, 3).join(" ");
      return firstTwoWords;
    },
  },
};
</script>
<style scoped>
.scrolling-container {
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}

.col-lg-3,
.col-md-4,
.col-sm-6 {
  flex: 0 0 auto;
  padding-right: 15px; /* Adjust spacing between items */
  padding-left: 15px; /* Adjust spacing between items */
}
.col-lg-3 {
  max-width: 25%; /* Display up to 4 items in a row on large screens */
}
.col-md-4 {
  max-width: 33.333%; /* Display up to 3 items in a row on medium screens */
}
.col-sm-6 {
  max-width: 50%; /* Display up to 2 items in a row on small screens */
}
.col-12 {
  max-width: 100%; /* Display 1 item in a row on extra small screens */
}

</style>