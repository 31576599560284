<template>
    <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-xs-12 justify-content-center">
      <h3 class="text-dark text-center bg-warning rounded-1">
        <i class="uil uil-fire text-danger"></i>
        HOT Deals!!
      </h3>
      <v-carousel cycle hide-delimiters style="max-height:min-content;">
        <v-carousel-item v-for="item in hotDeals" :key="item.id" class="overflow-auto">
          <v-img
            :src="item.product.images[0].image"
            :alt="item.product.title"
            :aspect-ration="16/9"
            contain
            class="mx-auto"
            style="max-height: 420px; width: 100%;"
          >
          </v-img>
          <div class="text-center">{{ item.variation ? item.variation.title : '' }} - {{ item.product.title }}</div>
            <v-card-actions class="d-flex justify-center">
              <v-btn color="warning" @click="visitRoute(item)">Shop Now</v-btn>
            </v-card-actions>
        </v-carousel-item>
      </v-carousel>
    </div>
</template>
<script>
export default {
  props: {
    hotDeals: Array,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    visitRoute(item) {
      this.$router.push({
        name: "Products",
        params: { category: item.product.maincategory },
      });
    },
  },
};
</script>
<style scoped>
.scrollable .v-card-text {
  max-height: 200px;
  /* Set a maximum height to enable scrolling */
  overflow-y: auto;
  /* Enable vertical scrolling */
}
.scrolling-container {
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}

.col-lg-3,
.col-md-4,
.col-sm-6 {
  flex: 0 0 auto;
  padding-right: 15px; /* Adjust spacing between items */
  padding-left: 15px; /* Adjust spacing between items */
}
.col-lg-3 {
  max-width: 25%; /* Display up to 4 items in a row on large screens */
}
.col-md-4 {
  max-width: 33.333%; /* Display up to 3 items in a row on medium screens */
}
.col-sm-6 {
  max-width: 50%; /* Display up to 2 items in a row on small screens */
}
.col-12 {
  max-width: 100%; /* Display 1 item in a row on extra small screens */
}
</style>

