<template>
    <div class="container-fluid bg-light text-center">
        <v-row>
            <v-col class="col-12 col-md-4 col-sm-12 ">
                <v-row>
                    <v-col class="col-12 col-sm-3 pr-4" align="right">
                        <v-icon class="display-2 text-warning">mdi-truck</v-icon>
                    </v-col>
                    <v-col class="col-12 col-sm-9 pr-4">
                        <h3 class="font-weight-light">FREE SHIPPING & RETURN</h3>
                        <p class="font-weight-thin">Free Shipping over $300</p>
                    </v-col>
                </v-row>
            </v-col>
            <v-col class="col-12 col-md-4 col-sm-12">
                <v-row>
                    <v-col class="col-12 col-sm-3 pr-4" align="right">
                        <v-icon class="display-2 text-warning">mdi-cash-usd</v-icon>
                    </v-col>
                    <v-col class="col-12 col-sm-9 pr-4">
                        <h3 class="font-weight-light">MONEY BACK GUARANTEE</h3>
                        <p class="font-weight-thin">30 Days Money Back Guarantee</p>
                    </v-col>
                </v-row>
            </v-col>
            <v-col class="col-12 col-md-4 col-sm-12">
                <v-row>
                    <v-col class="col-12 col-sm-3 pr-4" align="right">
                        <v-icon class="display-2 text-warning">mdi-headset</v-icon>
                    </v-col>
                    <v-col class="col-12 col-sm-9 pr-4">
                        <h3 class="font-weight-light">{{ business.contact_number }}</h3>
                        <p class="font-weight-thin">24/7 Available Support</p>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </div>
</template>
<script>
export default {
    data(){
        return{
            business:null,
        }
    },
    mounted(){
        this.business = JSON.parse(sessionStorage.business_location);
    }
}
</script>