var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-xl-3 col-lg-4 col-md-12 col-sm-12 col-xs-12 overflow-auto"},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"btn btn-light",attrs:{"icon":""}},[_c('v-icon',{staticClass:"text-dark"},[_vm._v("mdi-widgets")]),_vm._v("Categories "),_c('v-btn',{staticClass:"btn btn-warning"},[_c('router-link',{staticClass:"badge badge-pill",attrs:{"to":{ name: 'Products' }}},[_vm._v("Se all>")])],1)],1),_c('v-divider'),[_c('v-treeview',{attrs:{"items":_vm.maincats,"open":_vm.open},scopedSlots:_vm._u([{key:"prepend",fn:function(ref){
var item = ref.item;
return [(item.children)?_c('v-icon',{staticClass:"text-warning"},[_vm._v("mdi-folder")]):_c('v-icon',{staticClass:"text-info"},[_vm._v("mdi-file")])]}},{key:"label",fn:function(ref){
var item = ref.item;
return [(item.link)?_c('router-link',{attrs:{"to":{ name: item.link }}},[_vm._v(_vm._s(item.name))]):_c('router-link',{attrs:{"to":{
              name: 'Products',
              params: { category: item, children: item.children },
            }}},[_vm._v(_vm._s(item.name))])]}}])})],_c('v-divider')],2),_c('div',{staticClass:"row"},[_c('b-modal',{attrs:{"id":"add-cat","title":_vm.modattitle,"size":"lg"}},[_c('addCategory')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }